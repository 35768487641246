import Vue from 'vue'
import Router from 'vue-router'

const Login = () => import('@/pages/login')
const Home = () => import('@/pages/home')
const Register = () => import('@/pages/register/register.vue')
const resetPassword = () => import('@/pages/resetPassword/resetPassword.vue')
const QCGF = () => import('@/components/QCGF/QCGF.vue')
const ZCZB = () => import('@/components/ZCZB/ZCZB.vue')
const XXPX = () => import('@/components/XXPX/XXPX.vue')
const ZCZBDetail = () => import('@/components/ZCZBDetail/ZCZBDetail.vue')


const LearnAndDiscussion = () => import('@/components/LearnAndDiscussion/LearnAndDiscussion.vue')
const VideoLesson = () => import('@/components/VideoLesson/VideoLesson.vue')
const XSJX = () => import('@/components/XSJX/XSJX.vue')
const JGGK = () => import('@/components/JGGK/JGGK.vue')
const HYXW = () => import('@/components/HYXW/HYXW.vue')
const XW2 = () => import('@/components/XW2/XW2.vue')
const HY_LIST = () => import('@/components/HY_list/HY_list.vue');
const TZ_LIST = () => import('@/components/TZ_list/TZ_list.vue');
const SDSHYJB = () => import('@/components/SDSHYJB/SDSHYJB.vue')
const WYMD = () => import('@/components/WYMD/WYMD.vue');
const YYGZZD = () => import('@/components/YYGZZD/YYGZZD.vue');
const WYYGZZC = () => import('@/components/WYYGZZC/WYYGZZC.vue');
const ZJZGZZC = () => import('@/components/ZJZGZZC/ZJZGZZC.vue')
const ZKWJ = () => import('@/components/ZKWJ/ZKWJ.vue');
const GZSPX = () => import('@/components/GZSPX/GZSPX.vue')
const HLJZJH = () => import('@/components/HLJZJH/HLJZJH.vue');
const GJJ2024 = () => import('@/components/GJJ2024/GJJ2024.vue')
const JLS2024 = () => import('@/components/JLS2024/JLS2024.vue')
const GDS2024 = () => import('@/components/GDS2024/GDS2024.vue')
const SZ2024 = () => import('@/components/SZ2024/SZ2024.vue')
const YLZLKZBZ2024 = () => import('@/components/YLZLKZBZ2024/YLZLKZBZ2024.vue')
const HY20240622 = () => import('@/components/HY20240622/HY20240622.vue')
const HY20240623 = () => import('@/components/HY20240623/HY20240623.vue')
const HY20240701 = () => import('@/components/HY20240701/HY20240701.vue')
const HY20240712 = () => import('@/components/HY20240712/HY20240712.vue')
const HY20240723 = () => import('@/components/HY20240723/HY20240723.vue')
const HY20240724 = () => import('@/components/HY20240724/HY20240724.vue')
const HY20240812 = () => import('@/components/HY20240812/HY20240812.vue')
const HY20240815 = () => import('@/components/HY20240815/HY20240815.vue')
const HY20240827 = () => import('@/components/HY20240827/HY20240827.vue')
const HY20240828 = () => import('@/components/HY20240828/HY20240828.vue')
const HY20241018 = () => import('@/components/HY20241018/HY20241018.vue')
const HY20241021 = () => import('@/components/HY20241021/HY20241021.vue')
const HY20241114 = () => import('@/components/HY20241114/HY20241114.vue')
const HY20241203 = () => import('@/components/HY20241203/HY20241203.vue')
const HY20241208 = () => import('@/components/HY20241208/HY20241208.vue')


Vue.use(Router)

const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/home',
      component: Home,
      children: [
        // {
        //   path: '/home',
        //   redirect: () => {
        //     const loginInfo = localStorage.RightExamLoginInfo ? JSON.parse(localStorage.RightExamLoginInfo) : null
        //     let roles = []
        //     let accountType = ''
        //     if (loginInfo) {
        //       roles = loginInfo.roles || []
        //       accountType = loginInfo.accountType
        //     }
        //     if (accountType != 4) {
        //       if (roles.includes('更新协议')) {
        //         return '/updateProtocol'
        //       } else if (roles.includes('案件浏览')) {
        //         return '/allCase'
        //       } else if (roles.includes('角色查看')) {
        //         return '/roleControl'
        //       } else if (roles.includes('账号查看')) {
        //         return '/accounts'
        //       } else {
        //         return '/login'
        //       }
        //     }else{
        //       return '/allCase'
        //     }

        //   }
        // },
        {
          path: '/QCGF',
          component: QCGF
        },
        {
          path: '/ZCZB',
          component: ZCZB
        },
        {
          path: '/XXPX',
          component: XXPX
        },
        {
          path: '/ZCZBDetail/:Id/:Name',
          component: ZCZBDetail
        },
        {
          path: '/LearnAndDiscussion',
          component: LearnAndDiscussion
        },
        {
          path: '/VideoLesson/:Id',
          component: VideoLesson
        },
        {
          path: "/XSJX",
          component: XSJX
        },
        {
          path: "/HYXW",
          component: HYXW
        },
        {
          path: '/XW2',
          component: XW2
        },
        {
          path: '/JGGK',
          component: JGGK
        },
        {
          path: '/HY_list',
          component: HY_LIST
        },
        {
          path: '/TZ_list',
          component: TZ_LIST
        },
        {
          path: '/SDSHYJB',
          component: SDSHYJB,
        },
        {
          path: '/GZSPX',
          component: GZSPX,
        },
        {
          path: '/JGGK_WYMD',
          component: WYMD,
        },
        {
          path: '/JGGK_YYGZZD',
          component: YYGZZD,
        },
        {
          path: '/JGGK_WYYGZZC',
          component: WYYGZZC,
        },
        {
          path: '/JGGK_ZJZGZZC',
          component: ZJZGZZC,
        },
        {
          path: '/ZKWJ',
          component: ZKWJ
        },
        {
          path: '/HLJZJH',
          component: HLJZJH
        },
        {
          path: '/GJJ2024',
          component: GJJ2024
        },
        {
          path: '/JLS2024',
          component: JLS2024
        },
        {
          path: '/GDS2024',
          component: GDS2024
        },
        {
          path: '/SZ2024',
          component: SZ2024
        },
        {
          path: '/YLZLKZBZ2024',
          component: YLZLKZBZ2024
        },
        {
          path: '/HY20240622',
          component: HY20240622
        },
        {
          path: '/HY20240623',
          component: HY20240623
        },
        {
          path: '/HY20240701',
          component: HY20240701
        },
        {
          path: '/HY20240712',
          component: HY20240712
        },
        {
          path: '/HY20240723',
          component: HY20240723
        },
        {
          path: '/HY20240724',
          component: HY20240724
        },
        {
          path: '/HY20240812',
          component: HY20240812
        },
        {
          path: '/HY20240815',
          component: HY20240815
        },
        {
          path: '/HY20240827',
          component: HY20240827
        },
        {
          path: '/HY20240828',
          component: HY20240828
        },
        {
          path: '/HY20241018',
          component: HY20241018
        },
        {
          path: '/HY20241021',
          component: HY20241021
        },
        {
          path: '/HY20241114',
          component: HY20241114
        },
        {
          path: '/HY20241203',
          component: HY20241203
        },
        {
          path: '/HY20241208',
          component: HY20241208
        },
      ]
    },
    {
      path: '/register',
      component: Register,
    }, {
      path: '/resetPassword',
      component: resetPassword
    },
  ]
})

router.beforeEach((to, from, next) => {
  const loginInfo = localStorage.RightExamLoginInfo ? JSON.parse(localStorage.RightExamLoginInfo) : null
  let roles = []
  let accountType = ''
  if (loginInfo) {
    roles = loginInfo.roles
    accountType = loginInfo.accountType
  }
  if (accountType != 4) {
    if (to.fullPath.includes('/updateProtocol') && !roles.includes('更新协议')) {
      next('/home')
    } else if (to.fullPath.includes('/allCase') && !roles.includes('案件浏览')) {
      next('/home')
    } else if (to.fullPath.includes('/roleControl') && !roles.includes('角色查看')) {
      next('/home')
    } else if (to.fullPath.includes('/accounts') && !roles.includes('账号查看')) {
      next('/home')
    } else if (to.fullPath.includes('/caseDetail') && !roles.includes('案件详情')) {
      next('/home')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
